@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}


.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 10s ease-in;
}




p {
  font-size: 1.4rem;
}

.bubbles {
  margin-top: 2rem;
}

.bubble {
  transform-origin: left bottom 0px;
  margin-bottom: 20px;
  text-align: left;
  border-radius: 50px;
  background: white;
  display: inline-flex;
  color: black;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  position: relative;
  transition: transform 0.4s linear;
  cursor: pointer;

  @apply text-gray-900;

  &.single {
    @apply bg-blue-700 text-white;

    .bubble-progress {
      @apply bg-blue-300
    }

    .bubble-arrow {
      @apply text-blue-700
    }
  }

  &:hover {
    cursor: pointer;
    transform: rotate(-3deg);
    transition: transform 0.2s linear;
  }



  .bubble-progress {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #7d0101;
    border-radius: 50px;
    left: 0;
    top: 0;
    transition: width 0.5s ease;


  }


  .bubble-arrow {
    bottom: 1px;
    color: #fff;
    left: 32px;
    position: absolute;
    transform: translate3d(0, 100%, 0) scaleX(-1);
    width: 40px;
    height: 40px;

    .bubble-arrow-svg {
      width: 40px;
      height: 40px;
    }
  }
}


.bubble-wrap {

  &:nth-child(even) {
    @apply pl-16;

    .bubble {

      transform-origin: right bottom 0px;

      &:hover {
        cursor: pointer;
        transform: rotate(3deg);
        transition: transform 0.2s linear;
        transform-origin: right bottom;
      }
    }
    .bubble-arrow {
      transform: translate3d(0, 100%, 0) scaleX(1);
      right: 32px;
      left: auto;



    }
  }
}


.App {
  background-image: url(./img/bart-de-pauw.png);
  background-repeat: no-repeat;
  background-position: -150px bottom;
  background-size: 90vh;
  background-attachment: fixed;

}
